import DirectusSDK from '@directus/sdk-js'
import slugify from 'react-slugify'

const domain = 'http://multibratec.pt/api'

const client = new DirectusSDK({
  url: domain,
  project: '_',
  storage: window.localStorage
})

const getBanners = () => client
  .getItems('banners', {
    fields: '*,banner.filename'
  })
  .then(_data)

const getCategories = () => client
  .getItems('categories', {
    fields: '*,image.filename',
    sort: 'sort'
  })
  .then(_data)
  .then(_arrayWithSlug)

const getCategory = category => client
  .getItems('categories/' + category, {
    fields: '*,image.filename',
    single: true
  })
  .then(_data)
  .then(_objectWithSlug)

const getGlobal = () => client
  .getItems('global', {
    fields: '*,catalog.data.full_url',
    single: true
  })
  .then(_data)

const getHome = () => client
  .getItems('home', {
    fields: '*,image.filename',
    sort: 'sort'
  })
  .then(_data)

const getProduct = product => client
  .getItems('products/' + product, {
    fields: '*,image.filename',
    single: true
  })
  .then(_data)
  .then(_objectWithSlug)

const getProducts = (category) => client
  .getItems('products', {
    filter: { category },
    fields: '*,image.filename',
    sort: 'sort'
  })
  .then(_data)
  .then(_arrayWithSlug)

const sendContact = (formData) => client
  .createItem('contacts', formData)

/**
 * private
 */
const _data = data => data.data
const _arrayWithSlug = data => {
  return data.map(item => {
    item.slug = item.id + '-' + slugify(item.name)
    return item
  })
}
const _objectWithSlug = item => {
  item.slug = item.id + '-' + slugify(item.name)
  return item
}

export default {
  domain,
  getBanners,
  getCategories,
  getCategory,
  getGlobal,
  getHome,
  getProduct,
  getProducts,
  sendContact }