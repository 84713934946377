import React, { Component } from 'react'
import { BrowserRouter as  Router, Link } from 'react-router-dom'
import Api from './Api';


class Catalog extends Component {
  constructor () {
    super()
    this.state = {
      categories: []
    }
    document.title = 'Multibratec > Produtos'
  }

  async componentDidMount () {
    this.setState({
      catalog: await Api.getCategories()
    })
  }

  render () {
    return (
      <div className="pb-80 pt-80">
        <div className="page-title-cont page-title-small grey-light-bg">
          <div className="relative container align-left">
            <div className="row">
              
              <div className="col-md-6">
                <h1 className="page-title">Produtos</h1>
              </div>

            </div>
          </div>
        </div>
        <div className="page-section p-80-cont">
          <div className="container">
            <div className="row">
              {this.state.catalog && this.state.catalog.map(category =>
                <div className="col-sm-6 col-md-4 col-lg-3 pb-40" key={category.id}>
                  <div className="post-prev-img">
                    <Link to={'/catalog/' + category.slug}>
                      <img src={ Api.domain + '/thumbnail/_/728/728/crop/better/' + category.image.filename } alt={category.name} />
                    </Link>
                  </div>
                  <div className="post-prev-title mb-5">
                    <h3>
                      <Link to={'/catalog/' + category.slug} className="font-norm a-inv">{category.name}</Link>
                    </h3>
                  </div>        
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Catalog
