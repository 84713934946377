import React, { Component } from 'react'
import { BrowserRouter as  Router, Link } from 'react-router-dom'
import Api from './Api';

class Product extends Component {
  constructor () {
    super()
    this.state = {
      categories: []
    }
    document.title = 'Multibratec > Produtos'
  }

  async componentDidMount () {
    const category = await Api.getCategory(this.props.match.params.category)
    const product = await Api.getProduct(this.props.match.params.id)

    document.title = 'Multibratec > Produtos > ' + category.name + ' > ' + product.name

    this.setState({
      category,
      product
    })
  }

  render () {
    return (
      <div className="pb-80 pt-80">
        { this.state.product &&
          <div>
            <div className="page-title-cont page-title-small grey-light-bg">
              <div className="relative container align-left">
                <div className="row">
                  
                  <div className="col-sm-6">
                    <h1 className="page-title">{ this.state.product.name }</h1>
                  </div>
                  
                  <div className="col-sm-6">
                    <div className="breadcrumbs">
                      <Link to='/catalog'>PRODUTOS</Link>
                      <span className="slash-divider">/</span>
                      <Link to={ '/catalog/' + this.state.category.slug }>{ this.state.category.name }</Link>
                      <span className="slash-divider">/</span>
                      <span className="bread-current">{ this.state.product.name }</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="page-section p-80-cont">
              <div className="container">
                <div className="row">
                  <div className="col-sm-5 col-xs-12 mb-50" >
                    <div className="post-prev-img popup-gallery">
                      <img src={ Api.domain + '/thumbnail/_/728/728/contain/better/' + this.state.product.image.filename } alt={ this.state.product.name } />
                    </div>
                  </div>
                              
                  <div className="col-sm-7 col-xs-12 mb-50">
                    <h3 className="mt-0 mb-30">{ this.state.product.name }</h3>
                    <hr className="mt-0 mb-30" />
                    
                    <div className="mb-30 ql-editor" dangerouslySetInnerHTML={{__html: this.state.product.description}}></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
      </div>
    )
  }
}

export default Product
