import React, { Component } from 'react'
import Banners from './Banners.js'
import Api from './Api';

class Home extends Component {
  constructor () {
    super()
    this.state = {
      banners: []
    }

    document.title = 'Multibratec'
  }

  async componentDidMount () {
    this.setState({
      banners: await Api.getBanners(),
      homeContent: await Api.getHome()
    })
    window.revolution()
  }

  render () {
    return (
      <div className="pb-80 pt-80">
        <div className='relative'>
          <div className='rs-fullscr-container'>
            <div id='rs-fullwidth' className='tp-banner dark-bg'>
              <ul>
                {this.state.banners && this.state.banners.map(banner =>
                  <Banners banner={banner} key={banner.id} />
                )}
              </ul>
            </div>
          </div> 
        </div>

        {/* FEATURES 1 */}
        <div className="page-section p-60-cont">
          <div className="container">
            <div className="row">
              <div className="col-lg-9" style={{float: 'none', margin: 'auto'}}>
                {this.state.homeContent && this.state.homeContent.map((home, i) =>
                  <div className="pt-20" key={i}>
                    <div className="row vertical-center">
                      <div className="vertical-center col-sm-8">
                        <div className="fes2-main-text-cont">
                          <div className="title-fs-45">
                            {home.title_1}
                            <br />
                            <span className="bold">{home.title_2}</span>
                          </div>
                          <div className="clearfix"></div>
                          <div className="fes2-text-cont ql-editor" dangerouslySetInnerHTML={{__html: home.content}}></div>
                        </div>
                      </div>
                      <div className="col-sm-4 home-image-item" style={i%2 ? {order: -1, marginRight: '40px'} : { marginLeft: '40px'}}>
                        <div className="vertical-center">
			  {home.image &&
                            <img src={ Api.domain + '/thumbnail/_/400/400/crop/better/' + home.image.filename } alt={ home.title_1 + ' ' + home.title_2 } />
			  }
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Home
