import React from 'react';
import { Link, withRouter } from 'react-router-dom';

const NavItem = ({ isActive, to, label }) => {
  let classes = [''];
  if (isActive) classes.push('current');

  return (
    <li className={classes.join(' ')}>
      <Link className='nav-link' to={to}>
      <div className='main-menu-title'>{label}</div>
      </Link>
    </li>
  );
};

export default withRouter(({ location, ...props }) => {
  const currentPath = '/' + location.pathname.substr(1).replace(/\/.*$/, '')
  const isActive = currentPath === props.to;

  return <NavItem {...props} isActive={isActive} />;
});

