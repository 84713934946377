import React, { Component } from 'react'
import Home from './Home.js'
import Catalog from './Catalog.js'
import Category from './Category.js'
import Contacts from './Contacts.js'
import Product from './Product.js'
import './App.css'
import { BrowserRouter as Router, Route, Link } from 'react-router-dom'
import $ from 'jquery'
import imagesLoaded from 'imagesloaded/imagesloaded.pkgd.min'
import NavItem from './NavItem';
import Api from './Api';

class App extends Component {
  constructor () {
    super()
    this.state = {
      global: []
    }
  }
  async componentDidMount () {
    // hide loading images
    imagesLoaded('body', function () {
      $('#loader3').fadeOut()
      $('#loader-overflow').delay(200).fadeOut(700)
    })

    this.setState({
      global: await Api.getGlobal()
    })
  }

  render () {
    return (
      <Router>
        <div className='grey-bg'>
          {/* HEADER */}
          <header id='nav' className='header header-1 no-transparent'>
            <div className='header-wrapper'>
              <div className='container-m-30 clearfix'>
                <div className='logo-row'>
                  {/* LOGO */}
                  <div className='logo-container-2'>
                    <div className='logo-2'>
                      <Link to='/' className='clearfix'>
                        <img src='/images/logo.png' className='logo-img' alt='Logo' />
                      </Link>
                    </div>
                  </div>
                  {/* BUTTON */}
                  <div className='menu-btn-respons-container'>
                    <button type='button' className='navbar-toggle btn-navbar collapsed' data-toggle='collapse' data-target='#main-menu .navbar-collapse'>
                      <span aria-hidden='true' className='icon_menu hamb-mob-icon' />
                    </button>
                  </div>
                </div>
              </div>
              {/* MAIN MENU CONTAINER */}
              <div className='main-menu-container'>
                <div className='container-m-30 clearfix'>
                  {/* MAIN MENU */}
                  <div id='main-menu'>
                    <div className='navbar navbar-default' role='navigation'>
                      {/* MAIN MENU LIST */}
                      <nav className='collapse collapsing navbar-collapse right-1024'>
                        <ul className='nav navbar-nav'>
                          {/* MENU ITEM */}
                          <NavItem to='/' label='QUEM SOMOS' />
                          <NavItem to='/catalog' label='PRODUTOS' />
                          { this.state.global.catalog &&
                            <li>
                              <a className='nav-link' href={this.state.global.catalog.data.full_url} target='_blank' rel='noopener noreferrer'>
                                <div className='main-menu-title'>CATÁLOGO</div>
                              </a>
                            </li>
                          }
                          <NavItem to='/contacts' label='CONTACTOS' />
                        </ul>
                      </nav>
                    </div>
                  </div>
                  {/* END main-menu */}
                </div>
                {/* END container-m-30 */}
              </div>
              {/* END main-menu-container */}
            </div>
            {/* END header-wrapper */}
          </header>

          <Route path='/' exact component={Home} />
          <Route path='/catalog' exact component={Catalog} />
          <Route path='/catalog/:id' exact component={Category} />
          <Route path='/catalog/:category/:id' exact component={Product} />
          <Route path='/contacts' component={() => <Contacts global={this.state.global} />} />

          <footer id='footer3' className='page-section pb-30 footer2-black'>
            <div className='container'> 
              <div className='footer-2-copy-cont clearfix'>
                <div className='left'>
                  <a className='footer-2-copy' href='/' target='_blank' rel='noopener noreferrer'>© Multibratec 2019</a>
                </div>              
              </div>
            </div>
          </footer>
        </div>
      </Router>
    )
  }
}

export default App
