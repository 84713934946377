import React, { Component } from 'react';
import $ from 'jquery';
import Api from './Api';

class Contacts extends Component {
  constructor (props) {
    super(props)
    this.state = {
      global: props.global
    }
    this.sendContact = this.sendContact.bind(this);
    
    document.title = 'Multibratec > Contactos'
  }

  sendContact = (event) => {
    event.preventDefault()

    const form = event.target;
    const data = new FormData(form);
    const formData = {}
    for (let name of data.keys()) {
      formData[name] = data.get(name,)
    }

    Api.sendContact(formData)
      .then(() => {
        this.setState({
          sent: true,
          error: false
        })
        // clear form
        $('input[type="email"], input[type="text"], textarea').val('')    
      })
      .catch(() => {
        this.setState({
          error: true
        })
      })

  }

  render () {
    return (
      <div className="pb-80 pt-80">
        <div className='page-title-cont page-title-small grey-light-bg'>
          <div className='relative container align-left'>
            <div className='row'>
              <div className='col-md-8'>
                <h1 className='page-title'>CONTACTOS</h1>
                <div className='page-sub-title'>
                  Entre em contacto conosco
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='page-section'>
          <div className='container-fluid'>
            <div className='row'>
              <div className='col-md-4'>
                <div className='contact-form-cont'>
                  <div className='cis-cont'>
                    <div className='cis-icon'>
                      <div className='icon icon-basic-map' />
                    </div>
                    <div className='cis-text'>
                      <h3><span className='bold'>ENDEREÇO</span></h3>
                      <p>{ this.state.global.address && this.state.global.address.split('\n').map((item, key) => <span key={key}>{item}<br/></span>) }</p>
                    </div>
                  </div>
                  <div className='cis-cont'>
                    <div className='cis-icon'>
                      <div className='icon icon-basic-mail' />
                    </div>
                    <div className='cis-text'>
                      <h3><span className='bold'>E-MAIL</span></h3>
                      <p><a href={ this.state.global.email }>{ this.state.global.email }</a></p>
                    </div>
                  </div>
                  <div className='cis-cont'>
                    <div className='cis-icon'>
                      <div className='icon icon-basic-smartphone' />
                    </div>
                    <div className='cis-text'>
                      <h3><span className='bold'>TELEFONE</span></h3>
                      <p>{ this.state.global.mobile_phone }</p>
                    </div>
                  </div>
                </div>
              </div>

              <div className='col-md-8'>
                <div className='contact-form-cont'>
                  {/* TITLE */}
                  <div className='mb-40'>
                    <h2 className='section-title'>CONTACTE-<span className='bold'>NOS</span></h2>
                  </div>

                  {/* CONTACT FORM */}
                  <div>
                    <form id='contact-form' noValidate='novalidate' onSubmit={this.sendContact}  className={'animated fadeIn ' + (this.state.sent ? 'hidden' : '')} >
                      <div className='row'>
                        <div className='col-md-12 mb-30'>
                          {/* <label>Your name *</label> */}
                          <input key='1' type='text' data-msg-required='Introduza o seu nome' maxLength='100' className='controled' name='name' id='name' placeholder='NOME' required='' aria-required='true' />
                        </div>
                      </div>

                      <div className='row'>
                        <div className='col-md-12 mb-30'>
                          {/* <label>Your email address *</label> */}
                          <input type='email' data-msg-required='Introduza o seu e-mail' data-msg-email='Please enter a valid email address' maxLength='100' className='controled' name='email' id='email' placeholder='E-MAIL' required='' aria-required='true' />
                        </div>
                      </div>

                      <div className='row'>
                        <div className='col-md-12 mb-40'>
                          {/* <label>Message *</label> */}
                          <textarea maxLength='5000' data-msg-required='Deixe uma mensagem' rows='3' className='controled' name='message' id='message' placeholder='MENSAGEM' required='' aria-required='true' />
                        </div>
                      </div>

                      <div className='row'>
                        <div className='col-md-12 text-center-xxs'>
                          <input type='submit' value='ENVIAR MENSAGEM' className='button medium gray' data-loading-text='a carregar...' />
                        </div>
                      </div>
                    </form>
                    <div id='contactSuccess' className={ 'alert alert-success animated fadeIn ' + (this.state.sent ? '' : 'hidden')} >
                      Obrigado, a sua mensagem foi-nos enviada.
                    </div>
                    <div id='contactError' className={ 'alert alert-danger animated shake ' + (this.state.error ? '' : 'hidden' )} >>
                      <strong>Erro!</strong> Ocorreu um erro a enviar a sua mensagem.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Contacts
