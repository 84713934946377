import React, { Component } from 'react'
import { BrowserRouter as  Router, Link } from 'react-router-dom'
import Api from './Api';

class Category extends Component {
  constructor () {
    super()
    this.state = {
      categories: []
    }
    document.title = 'Multibratec > Produtos'
  }


  async componentDidMount () {
    const category = await Api.getCategory(this.props.match.params.id)

    document.title = 'Multibratec > Produtos > ' + category.name

    this.setState({
      category,
      products: await Api.getProducts(this.props.match.params.id)
    })
  }

  render () {
    return (
      <div className="pb-80 pt-80">
        { this.state.category &&
          <div>
            <div className="page-title-cont page-title-small grey-light-bg">
              <div className="relative container align-left">
                <div className="row">
                  
                  <div className="col-sm-6">
                    <h1 className="page-title">{ this.state.category.name }</h1>
                  </div>
                  
                  <div className="col-sm-6">
                    <div className="breadcrumbs">
                      <Link to='/catalog'>PRODUTOS</Link>
                      <span className="slash-divider">/</span>
                      <span className="bread-current">{ this.state.category.name }</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            
            <div className="page-section p-80-cont">
              <div className="container">
                <div className="row">
                { this.state.products && this.state.products.map(product =>
                    <div className="col-sm-6 col-md-4 col-lg-3 pb-40" key={product.id}>
                      <div className="post-prev-img">
                        <Link to={ '/catalog/' + this.state.category.slug + '/' + product.slug }>
                          <img src={Api.domain + '/thumbnail/_/728/728/crop/better/' + product.image.filename} alt={product.name} />
                        </Link>
                      </div>
                      <div className="post-prev-title mb-5">
                        <h3>
                          <Link to={ '/catalog/' + this.state.category.slug + '/' + product.slug } className="font-norm a-inv">{product.name}</Link>
                        </h3>
                      </div>        
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        }
      </div>
    )
  }
}

export default Category
