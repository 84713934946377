import React, { Component } from 'react'

const domain = 'http://multibratec.pt/api'

class Banners extends Component {
  render () {
    const banner = this.props.banner
    return (
      <li
        data-transition="zoomout"
        data-slotamount="1"
        data-masterspeed="1500"
        data-thumb={domain + '/thumbnail/_/180/110/crop/better/' + banner.banner.filename}
        data-saveperformance="on"
        data-title="HASWELL">
        
        <img
          src='images/dummy.png'
          alt={banner.title}
          data-lazyload={domain + '/thumbnail/_/1920/979/crop/better/' + banner.banner.filename}
          data-bgposition="center top"
          data-bgfit="contain"
          data-bgrepeat="no-repeat" />
      </li>
    )
  }
}

export default Banners
